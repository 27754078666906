import react from 'react'
import TablaPuntuaciones from '../../componentes/TablaPuntuaciones'

export default function Home (){

    return (
        
        <TablaPuntuaciones/>
        
    )
}