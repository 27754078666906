import React from 'react'
import ListaJugadores from '../../componentes/ListaJugadores'

export default function Players (){

    return (
        
        <ListaJugadores/>
        
    )
}