export const jugadores = [
    {
        "nombre" :"MARIO",
        "imagen":"mario.png",
        "puntos":0,
        "goles":13,
        "asistencias":8,
        "partidosGanados":2,
        "partidosJugados":4,
        "tiro":66,
        "pase":85,
        "verticalidad":87,
        "ritmo":96,
        "defensa":83,
        "porteria":81,

    },
    {
        "nombre" :"FRAN", 
        "imagen":"fran.png",
        "puntos":0,
        "goles":5,
        "asistencias":2,
        "partidosGanados":1,
        "partidosJugados":4,
        "tiro":62,
        "pase":81,
        "verticalidad":74,
        "ritmo":76,
        "defensa":82,
        "porteria":70,

    },
    {
        "nombre" :"MARCOS",
        "imagen":"marcos.png",
        "puntos":0,
        "goles":10,
        "asistencias":8,
        "partidosGanados":3,
        "partidosJugados":4,
        "tiro":63,
        "pase":64,
        "verticalidad":57,
        "ritmo":85,
        "defensa":67,
        "porteria":77,

    },
    {
        "nombre" :"IENEI", 
        "imagen":"ienei.png",
        "puntos":0,
        "goles":5,
        "asistencias":2,
        "partidosGanados":2,
        "partidosJugados":2,
        "tiro":56,
        "pase":54,
        "verticalidad":62,
        "ritmo":71,
        "defensa":55,
        "porteria":60,

    },
    {
        "nombre" :"ILYAS",
        "imagen":"ilyas.png",
        "puntos":0,
        "goles":2,
        "asistencias":3,
        "partidosGanados":1,
        "partidosJugados":4,
        "tiro":45,
        "pase":53,
        "verticalidad":51,
        "ritmo":40,
        "defensa":72,
        "porteria":73,

    },
    {
        "nombre" :"JAIME",
        "imagen":"jaime.png",
        "puntos":0,
        "goles":2,
        "asistencias":4,
        "partidosGanados":2,
        "partidosJugados":4,
        "tiro":50,
        "pase":71,
        "verticalidad":57,
        "ritmo":72,
        "defensa":86,
        "porteria":60,

    },
    {
        "nombre" :"REINA", 
        "imagen":"reina.png",
        "puntos":0,
        "goles":9,
        "asistencias":5,
        "partidosGanados":2,
        "partidosJugados":3,
        "tiro":84,
        "pase":82,
        "verticalidad":94,
        "ritmo":85,
        "defensa":60,
        "porteria":40,

    },
    {
        "nombre" :"BRIAN", 
        "imagen":"brian.png",
        "puntos":0,
        "goles":0,
        "asistencias":0,
        "partidosGanados":0,
        "partidosJugados":0,
        "tiro":70,
        "pase":87,
        "verticalidad":80,
        "ritmo":75,
        "defensa":80,
        "porteria":65,

    },
    {
        "nombre" :"DANI", 
        "imagen":"dani.png",
        "puntos":0,
        "goles":10,
        "asistencias":6,
        "partidosGanados":1,
        "partidosJugados":3,
        "tiro":83,
        "pase":80,
        "verticalidad":67,
        "ritmo":75,
        "defensa":75,
        "porteria":96,

    },
    {
        "nombre" :"ZACA",
        "imagen":"zaca.png",
        "puntos":0,
        "goles":8,
        "asistencias":6,
        "partidosGanados":2,
        "partidosJugados":3,
        "tiro":96,
        "pase":70,
        "verticalidad":72,
        "ritmo":70,
        "defensa":70,
        "porteria":85,

    }
]
